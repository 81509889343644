import Payments from './Payments';

export default class AlmaPayment extends Payments {
  #installmentsCount;

  #getAddress(address) {
    return {
      company: address.Company,
      first_name: address.Firstname,
      last_name: address.Lastname,
      email: '',
      phone: address.MobilePhone,
      line1: address.Address1,
      line2: address.Address2,
      postal_code: address.Postcode,
      city: address.City,
      country: address.Country,
    };
  }
  //Payment
  #payment({ paymentMethod }) {
    const amount = this.cart?.Total?.ToPay?.TaxIncl;
    const idorder = this.cart.IdCart;

    return {
      purchase_amount: amount * 100,
      installments_count: this.#installmentsCount,
      shipping_address: this.#getAddress(this.addressService.addressDelivery),
      billing_address: this.#getAddress(this.addressService.addressInvoice),
      customer_cancel_url: '',
      deferred_months: 0,
      deferred_days: 0,
      ipn_callback_url: '',
      origin: 'online', //online,pos_device,pos_sms,pos_link,pos_terminal
      return_url: `${process.env.URL}cart/thanks/accepted?orderid=${idorder}&cartid=${this.cart.IdCart}`, ///cart/thanks/accepted?orderid=${orderRes.IdOrder}&cartid=${$this.cartService.idCart}
      ipn_callback_url: process.env.ALMA_IPN_CALLBACK_URL,
      locale: this.auth.ctx.i18n.locale,
      deferred: '', //'trigger',null
      deferred_description: '', //text
      expires_after: 2880, //2880
      custom_data: this.custom_data(paymentMethod),
    };
  }

  //Customer
  get #customer() {
    const user = this.auth.user.Customer;

    return {
      id: user.Id,
      //   created: "date",
      first_name: user.Firstname,
      last_name: user.Lastname,
      //   addresses: this.#addresses,
      email: user.Email,
      //   phone: "",
      // birth_date: user.birthDate,
      //   birth_place: "",
      //   card: {},
      //   banking_data_collected: true,
      is_business: false,
      business_id_number: 'Siren',
      business_name: 'acme',
    };
  }

  //Order
  get #order() {
    return {
      merchant_reference: '',
      merchant_url: '',
      data: {},
      customer_url: '',
      comment: '',
    };
  }

  async checkout({ installmentsCount = 1, paymentMethod }) {
    this.#installmentsCount = installmentsCount;
    const paymentOrder = {
      payment: this.#payment({ paymentMethod }),
      customer: this.#customer,
      order: this.#order,
    };
    return await this.http.ALMA.$post('payments', paymentOrder);
  }
}
